import React from 'react'
import styled from 'styled-components'
import GatsbyImage from 'gatsby-image'

import convertMarkdown from 'components/convertMarkdown'
import categoryToColors from 'components/categoryToColors'

import OutlineHeader from 'components/outline-header'
import OutlineTitle from 'components/outline-title'
import Image from 'components/image'
import Video from 'components/video'
import BodyCopy from 'components/body-copy'
import Quote, { QuoteContainer, Quote as QuoteBody, QuoteAttribution } from 'components/quote'
import { imgToPublicUrl } from 'components/components'

const BoxContainer = styled('div')`
  margin: 40px 0 37px 0;
  @media (min-width: 1550px) {
    margin-left: 0;
    margin-right: -237px;
  }
  ${QuoteContainer} {
    margin-left: 0;
    margin-right: 0;
  }
`
const Box = styled('div')`
  border: 1px solid #002955;
  padding: 1.5em 1.5em 0.25em 1.5em;
`
const BoxBody = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 17px;
  color: #4A4A4A;
  @media (min-width: 833px) {
    font-size: 18px;
  }
  p:first-child, ${BodyCopy}:first-child {
    margin-top: 0;
  }
`
const BoxImageContainer = styled('div')`
  background-color: ${props => props.backgroundColor || 'transparent'};
  background-image: url('${props => props.image}');
  background-size: contain;
  background-position: ${props => props.backgroundPosition || 'top center'};
  background-repeat: no-repeat;
  margin-bottom: 8px;
`
const BoxImage = styled('img')`
  width: 100%;
`
const BoxImg = ({ image, ...other }) => image && image.childImageSharp ? (
  <BoxImageContainer {...other}>
    <GatsbyImage fluid={image.childImageSharp.fluid} />
  </BoxImageContainer>
) : (
  <BoxImageContainer {...other}>
    <BoxImage src={imgToPublicUrl(image)} />
  </BoxImageContainer>
)
const BoxRow = styled('div')`
  @media (min-width: 1550px) {
    display: flex;
    justify-content: space-between;
  }
`
const VideoRow = styled('div')`
  @media (min-width: 1550px) {
    flex: 0 0 60%;
    order: 2;
  }
  ${QuoteContainer} {
    margin: 0 0 30px 0;
    p:first-child, ${BodyCopy}:first-child {
      margin-top: 0;
    }
  }
  ${QuoteBody} {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    font-size: 24px;
    letter-spacing: -0.01em;
    color: #000000;
    p, ${BodyCopy} {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      line-height: 34px;
      font-size: 24px;
      letter-spacing: -0.01em;
      color: #000000;
    }
  }
  ${QuoteAttribution} {
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: normal;
    line-height: 14px;
    font-size: 12px;
    color: #000000;
    p, ${BodyCopy} {
      font-family: Roboto Mono;
      font-style: normal;
      font-weight: normal;
      line-height: 14px;
      font-size: 12px;
      color: #000000;
    }
  }
`
const BodyRow = styled('div')`
  @media (min-width: 1550px) {
    flex: 1 0 35%;
    margin-right: 5%;
  }
`

const CaseStudy = ({ title, children, category, image, video, backgroundColor, backgroundPosition, quote, quoteAttribution }) => (
  <BoxContainer>
    <Box>
      <OutlineHeader color={(categoryToColors[category] || {}).color}>Case Study</OutlineHeader>
      <OutlineTitle>{ title }</OutlineTitle>
      <BoxRow>
        { image || video || quote ? (
          <VideoRow>
            { image ? <BoxImg image={image} backgroundColor={backgroundColor} backgroundPosition={backgroundPosition} /> : null }
            { video ? <Video src={video} /> : null }
            { quote ? <Quote attribution={quoteAttribution}>{ quote }</Quote> : null }
          </VideoRow>
        ) : null}
        <BodyRow>
          <BoxBody>
            { convertMarkdown(children) }
          </BoxBody>
        </BodyRow>
      </BoxRow>
    </Box>
  </BoxContainer>
)

export default CaseStudy
