import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Underline, { SimpleUnderline } from './underline'
import ArrowRight, { ArrowRightHideMobile } from './arrow-right'

const ReadThis = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 15px;
  letter-spacing: 0.03em;
  color: #4A4A4A;
  margin-bottom: 3px;
`
const ReadThisContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 16px;
  text-decoration: none;
`
const ReadThisLinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  margin-top: 16px;
  text-decoration: none;
`
const ContainerOrLink = ({ to, children }) => to ? <ReadThisLinkContainer to={to} children={children} /> : <ReadThisContainer children={children} />

const ReadUnderline = styled(SimpleUnderline)`
`

const ReadThisLink = ({ color, hideArrowMobile, to = '' }) => (
  <ContainerOrLink to={to}>
    <ReadUnderline width='2px' color={color}>
      <ReadThis>Read this</ReadThis>
    </ReadUnderline>
    { hideArrowMobile ? <ArrowRightHideMobile color={color} /> : <ArrowRight color={color} /> }
  </ContainerOrLink>
)

export default ReadThisLink
