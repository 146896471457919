import React from 'react'
import styled from 'styled-components'
import Markdown from 'markdown-to-jsx'

import OutlineHeader from 'components/outline-header'
import ReadThisLink from 'components/read-this-link'

import categoryToColors from 'components/categoryToColors'
import graphToArray from 'components/graphToArray'

const ReadNextContainer = styled('div')`
  margin-top: 71px;
  margin-bottom: 90px;
`
// same as ImageTableTitle
const ReadNextTitle = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
  font-size: 24px;
  letter-spacing: -0.01em;
  color: #4A4A4A;
  margin-bottom: 40px;
`
// same as OutlineBody
const ReadNextBody = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 17px;
  color: #4A4A4A;
`
const ReadNextArticleTitle = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  font-size: 32px;
  letter-spacing: -0.01em;
  color: #4A4A4A;
  margin-bottom: 20px;
`
const ReadNextHeader = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${ props => props.color || '#FF8F99'};
  margin-bottom: 8px;
  @media (min-width: 833px) {
    letter-spacing: 0.08em;
    line-height: 20px;
  }
`

const ReadNext = ({ articles, article, ...other }) => {
  const articleArray = graphToArray(articles)
  const selectedArticle = (article && articles) ? articleArray.find(({ frontmatter }) => frontmatter.title === article) : null
  const { category, title, body, children, description, to, path, subtitle } = (selectedArticle && selectedArticle.frontmatter || other || {})
  return (
    <ReadNextContainer>
      <ReadNextTitle>
        Related article
      </ReadNextTitle>
      <ReadNextHeader color={(categoryToColors[category] || {}).color}>
        { category }
      </ReadNextHeader>
      <ReadNextArticleTitle>
        { title }
      </ReadNextArticleTitle>
      <ReadNextBody>
        <Markdown>
          { subtitle || body || children || '' }
        </Markdown>
      </ReadNextBody>
      <ReadThisLink color={(categoryToColors[category] || {}).color} to={path || to || (selectedArticle && selectedArticle.fields.slug)} />
    </ReadNextContainer>
  )
}

export default ReadNext
