import React from 'react'
import styled from 'styled-components'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share'

import ArticleContext from 'components/article-context'

import EmailImg from 'img/icon_email.svg'
import FacebookImg from 'img/icon_facebook.svg'
import TwitterImg from 'img/icon_twitter.svg'
import LinkedinImg from 'img/icon_linkedin.svg'

const SocialContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    position: absolute;
    flex-direction: column;
    left: 10%;
    top: 50px;
  }
`
const Icon = styled('div')`
  flex: 0 0 50px;
  width: 50%;
  height: 50%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 13px;
  width: 50px;
  height: 50px;
`
const Email = styled(Icon)`
  background-image: url('${EmailImg}');
`
const Facebook = styled(Icon)`
  background-image: url('${FacebookImg}');
`
const Twitter = styled(Icon)`
  background-image: url('${TwitterImg}');
`
const Linkedin = styled(Icon)`
  background-image: url('${LinkedinImg}');
`

const SocialIcons = ({ path = '', title, description, href }) => {
  const url = href || `https://courseware.netlify.com${path}`
  return (
    <SocialContainer>
      <EmailShareButton url={url} subject={title}><Email /></EmailShareButton>
      <FacebookShareButton url={url}><Facebook /></FacebookShareButton>
      <TwitterShareButton url={url} title={title}><Twitter /></TwitterShareButton>
      <LinkedinShareButton url={url} title={title} description={description}><Linkedin /></LinkedinShareButton>
    </SocialContainer>
  )
}

const SocialWithContext = () => (
  <ArticleContext.Consumer>
    {value => <SocialIcons {...value} />}
  </ArticleContext.Consumer>
)

export default SocialWithContext
