import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import Layout from 'components/layout'
import Header, { headerMargin } from 'components/header'
import Seperator, { PageSeperator, PageLeftSeperator } from 'components/seperator'
import ReadThisLink from 'components/read-this-link'
import Underline from 'components/underline'
import Footer from 'components/footer'
import ImgContainer from 'components/img-container'
import DescriptionLink from 'components/description-link'
import MessageModal from 'components/message-modal'
import ImageQuote from 'components/image-quote'
import { scrollToHref } from 'components'

import graphToArray from 'components/graphToArray'
import categoryToColors from 'components/categoryToColors'

import ArrowDown from 'components/arrow-down-lg'
import ArrowRight from 'components/arrow-right-lg'

import BlueImage from 'img/blue-1.svg'
import YellowImage1 from 'img/yellow-1.svg'
import YellowImage2 from 'img/yellow-2.svg'
import ArrowLineImg from 'img/arrow-line.svg'
import ArrowLineSmImg from 'img/arrow-line-sm.svg'
import LoadingImg from 'img/loading.png'
import SquiggleImg from 'img/squiggle_fill_card.svg'

const ArrowYellow = styled(ArrowDown)`
  bottom: 0;
  align-self: flex-end;
  margin: auto;
  @media (min-width: 833px) {
    margin: auto 36px;
  }
`

const ArrowCoral = styled(ArrowRight)`
  float: right;
  align-self: center;
  margin: auto 0 auto auto;
  @media (min-width: 833px) {
    margin: auto 0 auto 32px;
  }
`

const BlueBackground = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media (min-width: 1440px) {
    padding-left: calc(50vw - 720px);
    padding-right: calc(50vw - 720px);
  }
`
const BlueText = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFF7EE;
  padding: 0 32px;
  box-sizing: border-box;
  line-height: 32px;
  font-size: 26px;
  @media (min-width: 833px) {
    font-size: 52px;
    line-height: 64px;
    padding: 0;
    max-width: 934px;
  }
`

const BlueImg = styled('div')`
  width: 144px;
  height: 288px;
  background-image: url('${BlueImage}');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
  top: -144px;
  z-index: 3;
  @media (min-width: 1024px) {
    width: 348px;
    height: 696px;
    top: -21.75rem;
    left: 0;
  }
`
const Yellow1 = styled('div')`
  width: 144px;
  height: 288px;
  background-image: url('${YellowImage1}');
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  pointer-events: none;
  display: none;
  @media (min-width: 1024px) {
    display: block;
    width: 486px;
    height: 1500px;
    right: 0;
    top: 0;
  }
`
const DescriptionContainer = styled('div')`
  position: relative;
  z-index: 2;
  @media (min-width: 1440px) {
    padding-left: calc(50vw - 720px);
    padding-right: calc(50vw - 720px);
  }
`
const Description = styled('div')`
  display: flex;
  background: #FFFFFF;
  border: 1px solid #002955;
  z-index: 3;
  margin: -42px 20px;
  flex-direction: column;
  @media (min-width: 833px) {
    flex-direction: row;
    margin: -76px 20px;
  }
`
const LeftColumn = styled('div')`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  @media (min-width: 833px) {
    display: flex;
    flex-direction: column;
    flex: 0 0 223px;
  }
`
const CountBoxContainer = styled('div')`
  flex: 1 0 auto;
  min-height: 112px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props => props.background || 'transparent'};
  border-bottom: 1px solid #002955;
  &:first-child {
    border-right: 1px solid #002955;
  }
  @media (min-width: 833px) {
    flex: 1 0 auto;
    border-right: 1px solid #002955;
    padding: 48px 0;
    &:last-child {
      border-bottom: 0;
    }
  }
`
const CountValue = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 48px;
  font-size: 36px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #4A4A4A;
  margin-bottom: 0;
  @media (min-width: 833px) {
    line-height: 48px;
    font-size: 64px;
    margin-bottom: 33px;
  }
`
const CountDescription = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #4A4A4A;
  @media (min-width: 833px) {
    line-height: 48px;
    font-size: 18px;
  }
`
const CountBox = ({ value, description, background }) => (
  <CountBoxContainer background={background}>
    <CountValue>{ value }</CountValue>
    <CountDescription>{ description }</CountDescription>
  </CountBoxContainer>
)
const RightColumn = styled('div')`
  flex: 1 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  @media (min-width: 833px) {
    padding: 50px;
  }
`
const DescriptionContent = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  font-size: 18px;
  letter-spacing: -0.01em;
  color: #4A4A4A;
  @media (min-width: 833px) {
    line-height: 53px;
    font-size: 32px;
    letter-spacing: 0.01em;
  }
`
const LinkRow = styled('div')`
  display: flex;
  margin-top: 62px;
  flex-direction: column;
  @media (min-width: 833px) {
    flex-direction: row;
    justify-content: space-between;
  }
`
const YellowBackground = styled('div')`
  background: #FFE200;
  padding: 76px 20px 30px 20px;
  position: relative;
  @media (min-width: 1440px) {
    padding-left: calc(50vw - 700px);
    padding-right: calc(50vw - 700px);
  }
`
const Block = styled('div')`
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;
  z-index: 2;
  border-bottom: 1px solid #002955;
  @media (min-width: 833px) {
    flex: 0 0 277px;
    min-width: 277px;
    min-height: 277px;
    padding: 8px;
    margin: -1px 0 0 -1px;
  }
  @media (min-width: 1024px) {
    flex: 0 0 328px;
    min-width: 328px;
    min-height: 328px;
    padding: 22px;
  }
  @media (min-width: 1400px) {
    flex: 0 0 454px;
    min-width: 454px;
    min-height: 454px;
    padding: 40px;
  }
`
const LessonsContainer = styled('div')`
  margin-bottom: 60px;
  @media (min-width: 1024px) {
    max-width: 58.375rem;
    box-sizing: border-box;
    padding: 62px 117px;
    margin-right: 486px;
    margin-bottom: 0;
  }
`
const LessonsHeader = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  line-height: 47px;
  font-size: 36px;
  letter-spacing: 0.01em;
  color: #4A4A4A;
`
const PageBodyBase = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  font-size: 17px;
  letter-spacing: -0.01em;
  color: #4A4A4A;
  @media (min-width: 833px) {
    font-family: Poppins;
    font-size: 20px;
    line-height: 32px;
  }
`
const LessonsDescription = styled(PageBodyBase)`
`
const ArticleBlock = styled(Block)`
  border-left: 1px solid #002955;
  border-right: 1px solid #002955;
  border-bottom: 1px solid #002955;
  background: #FFFFFF;
  z-index: 2;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 20px;
  &:nth-child(2) {
    border-top: 1px solid #002955;
  }
  &:last-child {
    border-bottom: 1px solid #002955;
  }
  @media (min-width: 833px) {
    border-top: 1px solid #002955;
    border-bottom: 1px solid #002955;
    padding: 22px;
  }
  @media (min-width: 1024px) {
    padding: 22px;
  }
  @media (min-width: 1400px) {
    padding: 40px;
  }
`
const IntroBaseBlock = styled(ArticleBlock)`
  border: 1px solid #002955;
  background: #FFFFFF;
  justify-content: space-around;
  align-items: flex-start;
  @media (min-width: 833px) {
    border-right: 0;
  }
`
const SquiggleBox = styled('div')`
  display: none;
  @media (min-width: 833px) {
    background: url('${SquiggleImg}') center / contain no-repeat;
    display: block;
  }
`
const ArrowLine = styled('div')`
  width: 99px;
  height: 106px;
  background-image: url('${ArrowLineSmImg}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  right: 41%;
  bottom: -98px;
  @media (min-width: 833px) {
    width: 210px;
    height: 235px;
    background-image: url('${ArrowLineImg}');
    display: block;
    left: 186px;
    bottom: -210px;
    right: initial;
  }
  @media (min-width: 1440px) {
    left: calc(50vw - 720px + 186px);
  }
`
const LinkAndArrow = styled('div')`
  display: flex;
  margin-bottom: 64px;
  width: 350px;
  justify-content: space-between;
  width: 100%;
  @media (min-width: 833px) {
    order: initial;
    margin-bottom: 0;
    width: auto;
  }
`
const ReadLink = styled(LinkAndArrow)`
  order: 1;
`
const SeeLink = styled(LinkAndArrow)`
  order: 2;
`
const TopSeperator = styled(Seperator)`
  margin-bottom: 16px;
  @media (min-width: 1024px) {
    display: none;
  }
`
const BottomSeperator = styled(Seperator)`
  width: 120px;
  margin-bottom: 32px;
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`
const ArticleDescription = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 18px;
  color: #4A4A4A;
  @media (min-width: 1024px) {
    line-height: 32px;
    font-size: 20px;
    letter-spacing: -0.01em;
  }
`
const TopSection = styled('div')`
`
const ArticleLink = styled(Link)`
  flex: 1 0 auto;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  text-decoration: none;
`
const ArticleCard = ({ id, color, header, title, subtitle, description, path }) => (
  <ArticleBlock>
    <ArticleLink to={path}>
      <TopSection>
        <ArticleHeader color={color}>{ header }</ArticleHeader>
        <ArticleTitle>{ title }</ArticleTitle>
        <BottomSeperator color={color} />
        <ArticleDescription>{ subtitle }</ArticleDescription>
      </TopSection>
      <ReadThisLink color={color} hideArrowMobile={true} />
    </ArticleLink>
  </ArticleBlock>
)
const BlockContainerOrig = styled('div')`
  display: block;
  position: relative;
  z-index: 2;
  @media (min-width: 833px) {
    display: flex;
    flex-wrap: wrap;
    margin: 74px 0;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(277px,1fr));
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(328px,1fr));
  }
  @media (min-width: 1400px) {
    grid-template-columns: repeat(auto-fill, minmax(454px,1fr));
  }
`
const BlockContainerID = styled('div')`
  position: absolute;
  top: -54px;
`
const BlockContainer = ({ id, children }) => (
  <BlockContainerOrig>
    <BlockContainerID id={id} />
    { children }
  </BlockContainerOrig>
)
const ArticleCards = ({ category, data }) => {
  const array = graphToArray(data)
  const order = ['Product', 'Market', 'Customer']
  return array.filter((d) => {
    if (category && d.frontmatter.category !== category) return null
    return d
  }).filter(x => x).sort((a, b) => {
    if (a.frontmatter.category === b.frontmatter.category) {
      return (a.frontmatter.order || 999) - (b.frontmatter.order || 999);
    }
    return order.indexOf(a.frontmatter.category) - order.indexOf(b.frontmatter.category)
  }).map((d, i) => {
    if (d.frontmatter.category === 'About') return null
    return (
      <ArticleCard
        key={i}
        id={d.id}
        path={d.frontmatter.path}
        color={categoryToColors[d.frontmatter.category].color}
        header={d.frontmatter.category}
        title={d.frontmatter.title}
        description={d.frontmatter.description}
        subtitle={d.frontmatter.subtitle}
      />
    )
  })
}

const MobileLeftRow = styled('div')`
  flex: 0 0 112px;
  @media (min-width: 833px) {
    display: flex;
    flex-direction: column;
    flex: 2 0 auto;
  }
`
const MobileRightRow = styled('div')`
  flex: 1 0 auto;
  ${CountBoxContainer} {
    min-height: 100%;
    box-sizing: border-box;
    border-left: 1px solid #002955;
  }
  ${CountValue} {
    line-height: 48px;
    font-size: 64px;
    margin-bottom: 33px;
  }
  @media (min-width: 833px) {
    display: flex;
    flex: 1 0 auto;
    ${CountBoxContainer} {
      border-width: 0 1px 0 0;
    }
    ${CountValue} {
      line-height: 48px;
      font-size: 64px;
      margin-bottom: 33px;
    }
  }
`
const ArticleHeader = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: ${ props => props.color || '#00A4A6'};
  margin-bottom: 8px;
  @media (min-width: 1024px) {
    margin-bottom: 20px;
    font-size: 18px;
  }
`
const ArticleTitle = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
  font-size: 22px;
  color: #4A4A4A;
  margin-bottom: 8px;
  @media (min-width: 1024px) {
    line-height: 47px;
    font-size: 36px;
    margin-bottom: 24px;
  }
`
const BlueVideoContainer = styled('div')`
  overflow: hidden;
  position: relative;
  padding-top: 435px;
  ${headerMargin}
  @media (min-width: 1024px) {
    padding-top: 42.5%;
  }
`
const BlueVideoFrame = styled('iframe')`
  position: absolute;
  border: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  min-width: 1024px;
  background-image: url('${LoadingImg}');
  background-size: cover;
`
const BlueOverlay = styled('div')`
  background: #3D87FF;
  mix-blend-mode: multiply;
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 2;
`
const BlueOverline = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin: 0 auto 42px auto;
  color: #FFF7EE;
  line-height: 24px;
  font-size: 14px;
  max-width: 250px;
  @media (min-width: 1024px) {
    line-height: 1.5rem;
    font-size: 1.125rem;
    max-width: 315px;
    margin: 0 auto 24px auto;
  }
`

const BlueVideo = ({ overline1, title1 }) => (
  <BlueVideoContainer>
    <BlueBackground>
      <BlueOverline>
        { overline1 }
      </BlueOverline>
      <BlueText>
        { title1 }
      </BlueText>
    </BlueBackground>
    <BlueOverlay />
    <BlueVideoFrame src="https://player.vimeo.com/video/298443426?background=1" allowFullScreen />
  </BlueVideoContainer>
)

const GrayBackground = styled('div')`
  border-top: 20px solid #FFE200;
  padding: 36px 20px 60px 20px;
  background: #FCFCFC;
`
const PageOverline = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #3D87FF;
  margin-bottom: 23px;
  line-height: 24px;
  font-size: 14px;
  @media (min-width: 1024px) {
    font-size: 18px;
  }
`
const PageHeader = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #4A4A4A;
  margin-bottom: 50px;
  margin: 0 auto;
  position: relative;
  z-index: 4;
  padding: 0 8px;
  line-height: 38px;
  font-size: 32px;
  @media (min-width: 1024px) {
    max-width: 716px;
    line-height: 72px;
    font-size: 64px;
    padding: 0;
    margin-bottom: 40px;
  }
`
const PageBody = styled(PageBodyBase)`
  text-align: center;
  max-width: 604px;
  margin: 0 auto;
`
const ReadContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`
const WhiteBackground = styled('div')`
  padding: 62px 0;
  @media (min-width: 1024px) {
    padding: 163px 0;
  }
  @media (min-width: 1440px) {
    padding-left: calc(50vw - 700px);
    padding-right: calc(50vw - 700px);
  }
`
const BlockHeader = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  font-size: 32px;
  letter-spacing: -0.01em;
  color: ${props => props.color || '#3D87FF'};
  margin-bottom: 5px;
  @media (min-width: 1024px) {
    line-height: 47px;
    font-size: 42px;
  }
  @media (min-width: 1400px) {
    line-height: 72px;
    font-size: 64px;
    letter-spacing: 0.01em;
  }
`
const BlockDescription = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  font-size: 17px;
  color: #4A4A4A;
  @media (min-width: 1024px) {
    line-height: 34px;
    font-size: 20px;
    color: #4A4A4A;
  }
`
const IntroBlock = ({ title, description, color }) => (
  <IntroBaseBlock>
    <BlockHeader color={color}>
      { title }
    </BlockHeader>
    <BlockDescription>
      { description }
    </BlockDescription>
  </IntroBaseBlock>
)
class IndexPage extends Component {
  state = {
    showLetter: null,
  }
  closeLetter = () => {
    this.setState({ showLetter: false });
  }
  showLetter = () => {
    this.setState({ showLetter: true });
  }
  componentDidMount() {
    const { data } = this.props
    window.data = data
    window.props = this.props
    const href = window.location.hash
    scrollToHref({ href })
  }
  render() {
    const { data } = this.props
    if (typeof window !== 'undefined') {
      window.data = data
    }
    //console.log(props)
    const { articleCards, content } = (data || {})
    const { frontmatter } = (content || {})
    const { category, overline1, title1, overline2, title2, description2, link2, title3, description3, sections } = (frontmatter ? frontmatter : this.props)
    const [ section1, ...otherSections ] = (sections || [])
    return (
      <Layout data={data} checkData={true}>
        <Header />
        <BlueVideo overline1={overline1} title1={title1} />
        <MessageModal showModal={this.state.showLetter} closeModal={this.closeLetter} />
        <ImgContainer>
          <BlueImg />
        </ImgContainer>
        <GrayBackground>
          <PageOverline>{ overline2 }</PageOverline>
          <PageHeader>{ title2 }</PageHeader>
          <PageSeperator />
          <PageBody>
            { description2 }
            <ReadContainer>
              <ReadThisLink to={link2} color="#3D87FF" />
            </ReadContainer>
          </PageBody>
        </GrayBackground>
        <ImgContainer>
          <Yellow1 />
        </ImgContainer>
        <WhiteBackground>
          <ImageQuote {...(section1 ? section1.imageQuotes[0] : {})} />
        </WhiteBackground>
        <YellowBackground>
          <LessonsContainer>
            <LessonsHeader>{ title3 }</LessonsHeader>
            <PageLeftSeperator color="#C7B100" />
            <LessonsDescription>{ description3 }</LessonsDescription>
          </LessonsContainer>
          {(otherSections || []).map(({ category, title, body, imageQuotes }, i) => (
            <React.Fragment key={`index:section:${i}`}>
              <BlockContainer id={category && category.toLowerCase()}>
                <IntroBlock
                  title={title}
                  description={body}
                  color={categoryToColors[category] && categoryToColors[category].color}
                />
                <ArticleCards category={category} data={articleCards} />
                <SquiggleBox />
              </BlockContainer>
              {(imageQuotes || []).map((props, j) => (
                <ImageQuote key={`index:section:${i}:imageQuote:${j}`} mobilePadding={true} color="#C7B100" {...props} />
              ))}
            </React.Fragment>
          ))}
        </YellowBackground>
        <Footer showLetter={this.showLetter} />
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query Index($id: String!) {
    articleCards: allMarkdownRemark(filter:{frontmatter:{templateKey:{eq:"article"}}}) {
      edges {
        node {
          frontmatter {
            path
            category
            title
            subtitle
            description
          }
        }
      }
    }
    content: markdownRemark(id: { eq: $id }) {
      frontmatter {
        overline1
        title1
        overline2
        title2
        description2
        link2
        title3
        description3
        sections {
          category
          title
          body
          imageQuotes {
            quoteName
            quotePosition
            alignLeft
            quoteBody
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            audioFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
