import React from 'react'
import styled from 'styled-components'
import convertMarkdown from 'components/convertMarkdown'

import BodyCopy from 'components/body-copy'
import { ImageCaption } from 'components/image'

const MonospaceQuoteContainer = styled('div')`
  margin: 40px -38px;
  @media (min-width: 833px) {
    margin-left: -70px;
    margin-right: -70px;
  }
  @media (min-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
  }
`
const MonospaceQuote = styled('div')`
  background: #FFF7EE;
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  font-size: 12px;
  color: #4A4A4A;
  padding: 30px;
  p, ${BodyCopy} {
    margin-top: 0;
    margin-bottom: 20px;
    background: #FFF7EE;
    font-family: Roboto Mono;
    font-style: normal;
    font-weight: normal;
    line-height: 15px;
    font-size: 12px;
    color: #4A4A4A;
  }
  @media (min-width: 833px) {
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
    justify-content: space-between;
  }
`
const MonospaceQuoteItem = styled('div')`
  @media (min-width: 833px) {
    flex: 0 0 calc(50% - 20px);
  }
`
const MonospaceQuoteTitle = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: bold;
  line-height: 25px;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-top: 40px;
  &:first-child {
    margin-top: 0;
  }
`
const MonospaceQuoteCaption = styled(ImageCaption)`

`

const Table = ({ attribution, children }) => (
  <MonospaceQuoteContainer>
    <MonospaceQuote>
      { children }
    </MonospaceQuote>
    { attribution ? <MonospaceQuoteCaption>{ attribution }</MonospaceQuoteCaption> : null }
  </MonospaceQuoteContainer>
)

export default Table

const TableItem = ({ title, children }) => (
  <MonospaceQuoteItem>
    <MonospaceQuoteTitle>
      { title }
    </MonospaceQuoteTitle>
    { convertMarkdown(children) }
  </MonospaceQuoteItem>
)

export { Table, TableItem }
