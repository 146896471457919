import React from 'react'
import styled from 'styled-components'

import { imgToPublicUrl } from 'components/components'

import PlayImg from 'img/play.svg'
import PauseImg from 'img/pause.svg'

const AudioContainer = styled('div')`
  font-family: Roboto Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 18px;
  color: #4A4A4A;
  padding-left: 44px;
  background: url('${props => props.isPlaying ? PauseImg : PlayImg}') left center / auto no-repeat;
  margin: 1rem 0;
  cursor: pointer;
`

const InvisibleAudio = styled('audio')`
  display: none;
`

export default class AudioPlayer extends React.Component {
  state = {
    isPlaying: false,
    duration: 0,
  }
  constructor(props) {
    super(props)
    this.audioRef = React.createRef()
  }
  toggleIsPlaying = () => {
    const { isPlaying } = this.state
    const newIsPlaying = !isPlaying
    this.setState({ isPlaying: newIsPlaying })
    const node = this.audioRef.current
    if (newIsPlaying) {
      node.play()
    } else {
      node.pause()
    }
  }
  setDuration = () => {
    const duration = this.audioRef.current.duration
    console.log(duration)
    this.setState({ duration })
  }
  componentDidMount() {
    this.audioRef.current && this.audioRef.current.addEventListener('loadedmetadata', this.setDuration)
  }
  componentWillUnmount() {
    this.audioRef.current && this.audioRef.current.removeEventListener('loadedmetadata', this.setDuration)
  }
  render() {
    const { audioFile } = this.props;
    const { isPlaying, duration } = this.state;
    const sourceUrl = imgToPublicUrl(audioFile)
    if (!sourceUrl) return null
    const roundedDuration = Math.round(duration)
    return (
      <AudioContainer isPlaying={isPlaying} onClick={this.toggleIsPlaying}>
        Audio clip of interview ({roundedDuration} seconds)
        <InvisibleAudio ref={this.audioRef} controls>
          <source src={sourceUrl} />
        </InvisibleAudio>
      </AudioContainer>
    )
  }
}
