import React from 'react'
import styled from 'styled-components'
import GatsbyImage from 'gatsby-image'

import { PageLeftSeperator } from 'components/seperator'
import { imgToPublicUrl } from 'components/components'
import AudioPlayer from 'components/audio-player'

import LeftImg from 'img/left.svg'
import RightImg from 'img/right.svg'
import DownImg from 'img/down.svg'

const QuoteContainer = styled('div')`
  position: relative;
  z-index: 2;
  @media (min-width: 1024px) {
    display: flex;
  }
  @media (min-width: 1400px) {
    padding: 0 10%;
    margin: 0 -120px;
  }
`

const Down = styled('div')`
  background: url('${DownImg}') center / contain no-repeat;
  width: 224px;
  height: 54px;
  @media (min-width: 1024px) {
    position: absolute;
    z-index: 1;
    top: 54px;
  }
`

const Left = styled(Down)`
  @media (min-width: 1024px) {
    width: 80px;
    height: 200px;
    right: -90px;
    background: url('${LeftImg}') center / contain no-repeat;
  }
`
const Right = styled(Down)`
  @media (min-width: 1024px) {
    width: 233px;
    height: 200px;
    left: -218px;
    background: url('${RightImg}') center / contain no-repeat;
  }
`

const ArrowLine = ({ alignLeft }) => {
  if (alignLeft) return <Left />
  return <Right />
}

const QuoteColumn = styled('div')`
  position: relative;
  flex: 1;
  @media (min-width: 1024px) {
    order: ${props => props.alignLeft ? '2' : '1'};
    margin: 0 120px;
    max-width: 699px;
  }
`
const ImageColumn = styled('div')`
  position: relative;
  flex: 1;
  @media (min-width: 833px) {
    order: ${props => props.alignLeft ? '1' : '2'};
  }
  @media (min-width: 1024px) {
    max-width: 50%;
    margin-top: 34px;
  }
  @media (min-width: 1400px) {
    max-width: 700px;
  }
`
const Image = styled('img')`
  width: 100%;
  position: relative;
  z-index: 2;
`
const QuoteBody = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.01em;
  color: #4A4A4A;
  line-height: 28px;
  font-size: 17px;
  display: ${ props => props.hideMobile ? 'none' : 'block'};
  @media (min-width: 1024px) {
    display: ${ props => props.hideDesktop ? 'none' : 'block'};
    line-height: 32px;
    font-size: 20px;
  }
`

const QuoteName = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.01em;
  color: #4A4A4A;
  line-height: 29px;
  font-size: 22px;
  @media (min-width: 1024px) {
    line-height: 36px;
    font-size: 32px;
  }
`

const QuotePosition = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.01em;
  color: #4A4A4A;
  line-height: 29px;
  font-size: 22px;
  @media (min-width: 1024px) {
    line-height: 36px;
    font-size: 32px;
  }
`

const Quote = styled('div')`
  padding: ${props => props.mobilePadding ? '60px 0' : '0 20px'};
  @media (min-width: 1024px) {
    padding: ${props => props.mobilePadding ? '30px 0' : '0 20px'};
    margin: 0 -38px;
  }
`

const ImageQuote = ({ children, image, quoteName, quotePosition, alignLeft, color, quoteBody, audioFile, mobilePadding, growBody }) => (
  <Quote mobilePadding={mobilePadding}>
    <QuoteContainer>
      <QuoteColumn alignLeft={alignLeft} growBody={growBody}>
        <QuoteBody hideMobile>
          { children || quoteBody }
        </QuoteBody>
      </QuoteColumn>
      <ImageColumn alignLeft={alignLeft} />
    </QuoteContainer>
    <QuoteContainer>
      <ImageColumn alignLeft={alignLeft}>
        {image && image.childImageSharp ? <GatsbyImage fluid={image.childImageSharp.fluid} /> : <Image src={imgToPublicUrl(image)} /> }
        <ArrowLine alignLeft={alignLeft} />
      </ImageColumn>
      <QuoteColumn alignLeft={alignLeft}>
        <QuoteBody hideDesktop>
          { children || quoteBody }
        </QuoteBody>
        <PageLeftSeperator color={color} mobileAlignLeft={true} />
        <QuoteName>
          { quoteName }
        </QuoteName>
        <QuotePosition>
          { quotePosition }
        </QuotePosition>
        <AudioPlayer audioFile={audioFile} />
      </QuoteColumn>
    </QuoteContainer>
  </Quote>
)

export default ImageQuote
