import React from 'react'
import styled from 'styled-components'
import convertMarkdown from 'components/convertMarkdown'
import categoryToColors from 'components/categoryToColors'

import OutlineHeader from 'components/outline-header'
import OutlineTitle from 'components/outline-title'

import BodyCopy from 'components/body-copy'

const OutlineBoxContainer = styled('div')`
  margin: 40px 0 37px 0;
  @media (min-width: 1550px) {
    margin-left: 0;
    margin-right: -237px;
  }
`
export const OutlineItem = styled('div')`
  border-top: 4px solid ${props => props.color || '#FF8F99'};
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  line-height: 25px;
  font-size: 18px;
  color: #4A4A4A;
  padding: 10px 0 20px 0;
  margin-bottom: 8px;
  @media (min-width: 1024px) {
    margin-bottom: 40px;
    flex: 0 0 42%;
    margin-right: 5%;
  }
  @media (min-width: 1550px) {
    margin-bottom: 40px;
    flex: 0 0 28%;
    margin-right: 5%;
  }
  p, ${BodyCopy} {
    margin-top: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    font-size: 18px;
    color: #4A4A4A;
  }
`
export const OutlineBox = styled('div')`
  border: 4px solid ${props => props.color || '#FF8F99'};
  padding: 40px 28px;
`

const BestPractices = (props) => {
  const { category, title, children } = props
  const { color } = (categoryToColors[category] || {})
  return (
    <OutlineBoxContainer>
      <OutlineBox color={color} data-category={category}>
        <OutlineHeader color={color}>Best Practices</OutlineHeader>
        <OutlineTitle>{ title }</OutlineTitle>
        { children }
      </OutlineBox>
    </OutlineBoxContainer>
  )
}

export default BestPractices


const OutlineBody = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 17px;
  color: #4A4A4A;
  @media (min-width: 833px) {
    font-size: 18px;
  }
`
const OutlineItems = styled('div')`
  @media (min-width: 833px) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -11px;
  }
  &[data-grow="true"] {
    ${OutlineItem} {
      @media (min-width: 833px) {
        flex: 1 0 calc(33% - 11px);
      }
    }
  }
`

const BestPracticesText = ({ children }) => (
  <OutlineBody>
    { convertMarkdown(children) }
  </OutlineBody>
)

const BestPracticesItems = ({ children }) => {
  const grow = (children.length < 3) ? true : false
  return (
    <OutlineItems data-grow={grow}>
      { children }
    </OutlineItems>
  )
}

const BestPracticesItem = ({ itemCount, children }) => (
  <OutlineItem itemCount={itemCount}>
    { convertMarkdown(children) }
  </OutlineItem>
)

export { BestPracticesText, BestPracticesItems, BestPracticesItem }
