import React from 'react'
import styled from 'styled-components'
import convertMarkdown from 'components/convertMarkdown'

import Grid, { GridItems, ImageTableTitle, ImageTableSubtitle, ImageTableBody } from 'components/grid'

const ProConContainer = styled('div')`
  margin: 32px 0;
`
const Column = styled('div')`
  @media (min-width: 833px) {
    flex: 0 0 calc(50% - 20px);
  }
`
const GridTitle = styled(ImageTableTitle)`
  margin-bottom: 16px;
`

const ProConItem = ({ title, children, image }) => {
  const body = convertMarkdown(children)
  return (
    <ProConContainer>
      <ImageTableSubtitle>{ title }</ImageTableSubtitle>
      <ImageTableBody>
        { body }
      </ImageTableBody>
    </ProConContainer>
  )
}
const ProConColumn = ({ title, children }) => (
  <Column>
    <GridTitle>{ title }</GridTitle>
    { children }
  </Column>
)

export default Grid

export { Grid as ProCon, ProConItem, GridItems as ProConItems, ProConColumn }
