import styled from 'styled-components'

import ArrowRightImg from 'img/arrow-right.svg'

const ArrowRight = styled('div')`
  height: 16px;
  width: 37px;
  background-color: ${props => props.color || '#00A4A6'};
  mask: url('${ArrowRightImg}') no-repeat center;
  float: right;
  align-self: center;
  margin-left: 16px;
`

export const ArrowRightHideMobile = styled(ArrowRight)`
  display: none;
  @media (min-width: 833px) {
    display: block;
  }
`

export default ArrowRight
