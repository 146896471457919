import React from 'react'
import styled from 'styled-components'
import convertMarkdown from 'components/convertMarkdown'
import { imgToPublicUrl } from 'components/components'

import BodyCopy from 'components/body-copy'
import UnorderedList from 'components/unordered-list'

const ImageTableContainer = styled('div')`
  margin: 40px 0;
  @media (min-width: 1550px) {
    margin-left: 0;
    margin-right: -237px;
  }
`
const ImageTable = styled('div')`
  background: #FFF7EE;
  padding: 40px 40px 16px 40px;
`
const ImageTableItemContainer = styled('div')`
  @media (min-width: 833px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`
const ImageTableItem = styled('div')`
  @media (min-width: 833px) {
    flex: 0 0 calc(50% - 20px);
  }
`
export const ImageTableTitle = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 26px;
  font-size: 24px;
  letter-spacing: -0.01em;
  color: #4A4A4A;
`
const ImageTableImg = styled('img')`
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #C4C4C4;
  display: block;
  margin: 40px 0;
  background-image: url('${props => props.image || ''}');
`
export const ImageTableSubtitle = styled('div')`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  font-size: 18px;
  color: #4A4A4A;
  margin-bottom: 4px;
`
export const ImageTableBody = styled('div')`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 18px;
  color: #4A4A4A;
  ${BodyCopy} {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    font-size: 18px;
    color: #4A4A4A;
  }
  ${UnorderedList} {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  ${UnorderedList} li {
    margin-bottom: 8px;
  }
`

const Grid = ({ children, title }) => (
  <ImageTableContainer>
    <ImageTable>
      <ImageTableTitle>{ title }</ImageTableTitle>
      <ImageTableItemContainer>
        { children }
      </ImageTableItemContainer>
    </ImageTable>
  </ImageTableContainer>
)

const GridItem = ({ title, children, image }) => (
  <ImageTableItem>
    <ImageTableImg src={imgToPublicUrl(image)} />
    <ImageTableSubtitle>{ title }</ImageTableSubtitle>
    <ImageTableBody>
      { convertMarkdown(children) }
    </ImageTableBody>
  </ImageTableItem>
)

export default Grid

export { Grid, GridItem, ImageTableItemContainer as GridItems }
