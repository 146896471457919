import React from 'react'
import Markdown from 'markdown-to-jsx'

import baseComponents from 'components/baseComponents'
import BestPractices from 'components/best-practices'
import CaseStudy from 'components/case-study'
import BodyCopy from 'components/body-copy'
import Image from 'components/image'
import SocialIcons from 'components/social-icons'

import Note from 'components/note'
import Quote from 'components/quote'
import { BestPracticesText, BestPracticesItems, BestPracticesItem } from 'components/best-practices'
import ReadNext from 'components/read-next'
import Grid, { GridItem } from 'components/grid'
import ProCon, { ProConItem, ProConColumn } from 'components/pro-con'
import Table, { TableItem } from 'components/table'
import Video from 'components/video'

const components = {
  ...baseComponents,
  'p': BodyCopy,
  'img': Image,
  'best-practices': BestPractices,
  'case-study': CaseStudy,
  'social-icons': SocialIcons,
}

export default components

const articleOverrides = {
  'BestPractices': BestPractices,
  'CaseStudy': CaseStudy,
  'SocialIcons': SocialIcons,
  'Note': Note,
  'Quote': Quote,
  'BestPracticesText': BestPracticesText,
  'BestPracticesItems': BestPracticesItems,
  'BestPracticesItem': BestPracticesItem,
  'ReadNext': ReadNext,
  'Image': Image,
  'Grid': Grid,
  'GridItem': GridItem,
  'ProCon': ProCon,
  'ProConItem': ProConItem,
  'ProConColumn': ProConColumn,
  'Table': Table,
  'TableItem': TableItem,
  'Video': Video,
}

const overrides = [ ...Object.entries(components), ...Object.entries(articleOverrides) ].reduce((collector, [key, component]) => {
  return {
    ...collector,
    [key]: {
      component,
    },
  };
}, {})

export const AutoMarkdown = ({ children, ...other }) => {
  return (
    <Markdown options={{overrides}} {...other}>
      { children }
    </Markdown>
  )
}

export const imgToPublicUrl = (img) => {
  if (typeof img === 'string') return img
  return (img && img.publicURL) ? img.publicURL : null
}
